<main>
  <div class="contact-area login-area grey-bg-2">
    <div class="container-fluid">
      <div class="">
        <div class="row">
          <div class="col-xl-6 col-lg-6 left-container" style="background-image: url('assets/images/bg10-l.jpg')">

            <div class="col-12 d-flex justify-content-center pt-70">
              <h1 class="mb-5 text-white">Welcome to</h1>
            </div>

            <div class="col-12 d-flex justify-content-center">
              <div class="tp-header-logo">
                <a routerLink="/index"><img src="assets/images/logo.png" alt=""></a>
              </div>
            </div>

            <div class="tp-contact-2-btn mt-40 text-center" style="margin-top: 35px;">
              <button type="submit" (click)="goDown1()" class="tp-btn-xl white-adnim">Get Started</button>
            </div>
            
            <div class="col-12 d-flex justify-content-center pt-70">

            </div>
          </div>
          <div class="col-xl-6 col-lg-6 right-container" id="right-container">
            <div class="contact-wrapper d-flex flex-column align-items-center">
              <h2 style="margin-bottom: 35px;">Login into your account</h2>

              <form *ngIf="!showOtpForm" [formGroup]="phoneForm" (ngSubmit)="onSubmitPhone()">
                <div class="tp-contact-2-form">
                  <div class="row justify-content-center mt-40">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-6">
                      <input type="number" pattern="\d*" formControlName="mobile" maxlength="10" placeholder="Enter Phone Number" class="form-control text-center" id="phoneNumber">
                    </div>
                    <div class="tp-contact-2-btn mt-40 text-center" style="margin-top: 35px;">
                      <button type="submit" class="tp-btn-xl green-anim">Submit Phone Number</button>
                    </div>
                  </div>
                </div>
              </form>

              <form *ngIf="showOtpForm" [formGroup]="otpForm" (ngSubmit)="onSubmitOtp()">
                <div class="tp-contact-2-form">
                  <div class="row justify-content-center mt-40">
                    <div class="col-xl-1 col-lg-1 col-md-1 col-3">
                      <input type="number" formControlName="otp0" minlength="1" maxlength="1" placeholder="" class="inputs p-0 text-center" #otpInput (keyup)="moveFocus($event, otpInputs.toArray()[1].nativeElement, null)">
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-1 col-3">
                      <input type="number" formControlName="otp1" minlength="1" maxlength="1" placeholder="" class="inputs p-0 text-center" #otpInput (keyup)="moveFocus($event, otpInputs.toArray()[2].nativeElement, otpInputs.toArray()[0].nativeElement)">
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-1 col-3">
                      <input type="number" formControlName="otp2" minlength="1" maxlength="1" placeholder="" class="inputs p-0 text-center" #otpInput (keyup)="moveFocus($event, otpInputs.toArray()[3].nativeElement, otpInputs.toArray()[1].nativeElement)">
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-1 col-3">
                      <input type="number" formControlName="otp3" minlength="1" maxlength="1" placeholder="" class="inputs p-0 text-center" #otpInput (keyup)="moveFocus($event, otpInputs.toArray()[4].nativeElement, otpInputs.toArray()[2].nativeElement)">
                    </div>
                    <!-- <div class="col-xl-1 col-lg-1 col-md-1 col-3">
                      <input type="number" formControlName="otp4" minlength="1" maxlength="1" placeholder="" class="inputs p-0 text-center" #otpInput (keyup)="moveFocus($event, otpInputs.toArray()[5].nativeElement, otpInputs.toArray()[3].nativeElement)">
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-1 col-3">
                      <input type="number" formControlName="otp5" minlength="1" maxlength="1" placeholder="" class="inputs p-0 text-center" #otpInput (keyup)="moveFocus($event, null, otpInputs.toArray()[4].nativeElement)">
                    </div> -->
                    <div class="tp-contact-2-btn mt-40 text-center" style="margin-top: 35px;">
                      <button type="submit" class="tp-btn-xl green-anim">Log in</button>
                    </div>
                    <div class="resend-otp mt-40 text-center" style="margin-top: 35px;">
                      <button type="button" class="tp-btn-xl" (click)="resendOtp()">Resend OTP</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
