import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { AuthService } from './auth.service'; // Import the AuthService
import { Location } from '@angular/common'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthManagerService {

  authcode: any;

  private apiUrl = '/Mobileapi/send_whatsapp_otp';
  private valUrl = '/Mobileapi/validate_otp';
  private apitoken = '609615431cd8d539b733876c7e0c511dSAGLcHZ6nxEBnE4XlJ1nmcPTZaOXOGIXW'

  private authApi = '/Mobileapi/giftvoucher_authorization_verify';
  private bearerTokenApi = '/Mobileapi/giftvoucher_get_bearer_token';

  constructor(private http: HttpClient, private router: Router) { }


  checkToken(mobile_no: string): void {
    const storedMobileNo = localStorage.getItem('microsite');
    if (storedMobileNo === mobile_no) {
      this.router.navigate(['/index']);
    } else {
      this.sendOtp(mobile_no, this.apitoken);
    }
  }

  sendOtp(mobile_no: string, token: string): void {
    this.http.post(this.apiUrl, { mobile_no: mobile_no, token: token })
      .subscribe(
        (response: any) => {
          if (response.status) {
            alert('Otp sent to WhatsApp');
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  async handleOtpSubmission(mobile_no: string, otp: string): Promise<void> {
    this.validateOtp(mobile_no, otp, this.apitoken).subscribe(
      async (response: any) => {
        if (response.response.status) {
          console.log('OTP validation successful:', response);
          localStorage.setItem('microsite', mobile_no);
          localStorage.setItem('profileData', JSON.stringify(response.response.data));

          this.router.navigate(['/index']);

          try {
            await this.fetchBearerToken();
          } catch (error) {
            console.error('Error fetching bearer token:', error);
          }

          
        } else {
          alert('OTP Verification failed. Please try again');
          console.log('OTP validation response:', response);
        }
      },
      error => {
        console.error('Error validating OTP:', error);
        alert('OTP Verification failed. Please try again.');
      }
    );
  }


  validateOtp(mobile_no: string, otp: string, token: string) {
    return this.http.post(this.valUrl, { mobile_no: mobile_no, otp: otp, token: token });
  }


  //-----------------authorization_code and bearer_token--------------------------------

  // async fetchBearerToken(): Promise<void> {
  //   try {
  //     const authorizationCode = await this.generateAuthCode();
  //     const payloads = {
  //       token: this.apitoken,
  //       clientId: '5e0417a9a61fbebbf22efb5c2646bee1',
  //       clientSecret: '7d180a544d33745e18c10811ab975ee7',
  //       authorizationCode: authorizationCode
  //     };

  //     const response: any = await this.http.post(this.bearerTokenApi, payloads).toPromise();

  //     if (!response?.response?.data) {
  //       console.error('Response format is invalid:', response);
  //       throw new Error('Invalid response format');
  //     }

  //     const dataString = response.response.data;

  //     let dataObject;
  //     try {
  //       dataObject = JSON.parse(dataString);
  //     } catch (parseError) {
  //       console.error('Error parsing response data:', parseError);
  //       throw new Error('Error parsing response data');
  //     }

  //     const bearerToken = dataObject.token;

  //     if (!bearerToken) {
  //       console.error('Bearer token not found in response data:', dataObject);
  //       throw new Error('Bearer token not found');
  //     }

  //     localStorage.setItem('bearerToken', bearerToken);
  //     console.log('Bearer token fetched and stored:', bearerToken);
  //   } catch (error) {
  //     console.error('Error fetching bearer token:', error);
  //     this.handleTokenRejected();
  //     throw error;
  //   }
  // }

  async fetchBearerToken(): Promise<void> {
    try {
        const authorizationCode = await this.generateAuthCode();
        const payloads = {
            token: this.apitoken,
            clientId: '5e0417a9a61fbebbf22efb5c2646bee1',
            clientSecret: '7d180a544d33745e18c10811ab975ee7',
            authorizationCode: authorizationCode
        };

        const response: any = await this.http.post(this.bearerTokenApi, payloads).toPromise();
        
        console.log('API Response:', response); 

        if (response.status !== 1 || !response.data) {
            console.error('Response format is invalid or an error occurred:', response);
            throw new Error('Invalid response format or error occurred');
        }

        const dataString = response.data;
        
        if (dataString.startsWith('<!DOCTYPE')) {
            console.error('Received HTML response instead of JSON:', dataString);
            throw new Error('Received HTML response instead of JSON');
        }

        let dataObject;
        try {
            dataObject = JSON.parse(dataString);
        } catch (parseError) {
            console.error('Error parsing response data:', parseError);
            throw new Error('Error parsing response data');
        }

        const bearerToken = dataObject.token;

        if (!bearerToken) {
            console.error('Bearer token not found in response data:', dataObject);
            throw new Error('Bearer token not found');
        }

        localStorage.setItem('bearerToken', bearerToken);
        console.log('Bearer token fetched and stored:', bearerToken);
    } catch (error) {
        console.error('Error fetching bearer token:', error);
        // this.handleTokenRejected();
        // throw error;
    }
}


  async ensureBearerToken(): Promise<string> {
    let bearerToken = localStorage.getItem('bearerToken');

    if (!bearerToken) {
      await this.fetchBearerToken();
      bearerToken = localStorage.getItem('bearerToken');
    }

    if (!bearerToken) {
      throw new Error('Bearer token could not be fetched or found');
    }

    return bearerToken;
  }

  handleTokenRejected(): void {
    localStorage.removeItem('microsite');
    localStorage.removeItem('profileData');
    localStorage.removeItem('bearerToken');
    this.router.navigate(['/otp']);
  }

  async generateAuthCode(): Promise<string> {
    const payloads = {
      token: this.apitoken,
      clientId: '5e0417a9a61fbebbf22efb5c2646bee1',
      username: 'centuryplysandbox@woohoo.in',
      password: 'WoohooUAT@1'
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });

    try {
      const response: any = await this.http.post(this.authApi, payloads, { headers }).toPromise();
      const dataString = response.response.data;
      const dataObject = JSON.parse(dataString);
      const authorizationCode = dataObject.authorizationCode;
      return authorizationCode;
    } catch (error) {
      console.error('Error fetching authorization code:', error);
      throw error;
    }
  }


  //---------logout---------
  logout(): void {
    // Clear local storage
    localStorage.removeItem('microsite');
    localStorage.removeItem('profileData');
    localStorage.removeItem('bearerToken');

    // Redirect to OTP submission page
    this.router.navigate(['/otp']);
  }


}