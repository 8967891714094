
<!-- Advertice portion -->

<div class="tp-funfact-2-area tp-funfact-2-bg pt-50 pb-50 p-relative">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <img src="assets/images/bg-1-1.jpg">
            </div>
        </div>
    </div>
</div>

<!-- End of Advertice portion -->

<footer>
    <div class="tp-copyright-area tp-copyright-space black-bg pt-25 pb-25">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="tp-copyright-left-text text-center">
                        <p>© Copyright {{ currentYear }} by <a class="no-underline" href="https://www.centuryply.com">www.centuryply.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</footer>