import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '../product.service';
import { HttpClient, HttpRequest, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { AuthManagerService } from '../auth-manager.service';
import { ViewportScroller } from '@angular/common';


@Component({
  selector: 'app-giftvouchers',
  templateUrl: './giftvouchers.component.html',
  styleUrls: ['./giftvouchers.component.css']
})
export class GiftvouchersComponent {

  categoryId: any;
  voucher_category_id: any;
  m_voucher_id: any;
  member_type: any;
  token: any = "609615431cd8d539b733876c7e0c511dSAGLcHZ6nxEBnE4XlJ1nmcPTZaOXOGIXW"
  products: any[] = [];
  profileData: any;
  bearerToken: any;
  selectedProduct: any;
  selectedPrice: number = 0;
  quantity: number = 1;
  selectedIndex: any;



  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private http: HttpClient,
    private authService: AuthService,
    private authManager: AuthManagerService,
    private scroller: ViewportScroller
  ) { }

  ngOnInit(): void {

    this.profileData = localStorage.getItem('profileData');
    this.bearerToken = localStorage.getItem('bearerToken');
    this.route.queryParams.subscribe(params => {
      this.categoryId = +params['categoryId'];
      this.fetchProducts();
    });
  }

  fetchProducts(): void {
    const member_type = JSON.parse(this.profileData).category;
    this.productService.fetchProducts(this.categoryId, member_type, this.token)
      .subscribe(
        (response) => {
          // console.log('API Response:', response.response);
          this.products = response.response.data.map((product: { product_details: string }) => ({
            ...product,
            product_details: JSON.parse(product.product_details)
          }));

          console.log(this.products);
          this.voucher_category_id = this.products[0].voucher_category_id;
          // console.log(this.voucher_category_id);
        },


        (error) => {
          // console.error('API Error:', error);
        }
      );

  }

  // onCardClick(product: any): void {
  //   this.selectedProduct = this.selectedProduct === product ? null : { ...product, quantity: 1 };

  //   setTimeout(() => { this.scroller.scrollToAnchor("giftCardDetails") }, 100);

  // }

  onCardClick(product: any): void {
    if (this.selectedProduct === product) {
      this.selectedProduct = null; 
    } else {
      this.selectedProduct = { ...product, selectedPrice: product.selectedPrice || 0 };
      this.quantity = 1; 
    }
  
    setTimeout(() => { this.scroller.scrollToAnchor("giftCardDetails"); }, 100);
  }
  

  setIndex(index: number) {
    this.selectedIndex = index;
  }


  // onCheckboxChange(event: Event, product: any): void {
  //   const input = event.target as HTMLInputElement;
  //   const denomination = parseFloat(input.value);

  //   if (input.checked) {
  //     product.selectedPrice = denomination; // Set the selected denomination for the product
  //   }

  //   console.log('Selected denomination for product:', product.sku, product.selectedPrice);
  // }


  onCheckboxChange(event: Event, product: any): void {
    const input = event.target as HTMLInputElement;

    product.selectedPrice = parseFloat(input.value);

    console.log('Selected denomination for product:', product.sku, product.selectedPrice);
  }


  // async placeOrder(product: any): Promise<void> {
  //   if (!product) {
  //     alert('Please select a product before placing an order.');
  //     return;
  //   }

  //   const hasDenominations = product.selected_denominations && product.selected_denominations.length > 0;

  //   if (hasDenominations && !product.selectedPrice) {
  //     alert('Please select a denomination before placing an order.');
  //     return;
  //   } else if (!hasDenominations) {
  //     product.selectedPrice = 100;
  //   }

  //   // Confirm order placement
  //   const confirmOrder = confirm('Are you sure you want to place this order?');
  //   if (!confirmOrder) {
  //     return;
  //   }

  //   try {
  //     const bearerToken = await this.authManager.ensureBearerToken();

  //     const architect_code = JSON.parse(this.profileData).user_code;
  //     const member_type = JSON.parse(this.profileData).category;
  //     const voucher_category_id = this.voucher_category_id;

  //     const formData: FormData = new FormData();
  //     formData.append('sku', product.sku || '');
  //     formData.append('price', product.selectedPrice.toString());
  //     formData.append('qty', this.quantity.toString());
  //     formData.append('giftMessage', product.product_details.description || '');
  //     formData.append('architect_code', architect_code);
  //     formData.append('bearerToken', bearerToken);
  //     formData.append('voucher_category_id', voucher_category_id);
  //     formData.append('member_type', member_type);
  //     formData.append('sync_only', this.quantity <= 4 ? 'true' : 'false');

  //     this.showLoadingIndicator();  // Show loading indicator

  //     this.productService.createOrder(formData).subscribe(
  //       async (response) => {
  //         this.hideLoadingIndicator();  // Hide loading indicator

  //         const status = response?.response?.data?.data?.status;

  //         if (status === 'PROCESSING') {
  //           alert(`Order is currently processing. Order ID: ${response.response.data.data.orderId}`);
  //         } else if (response?.response?.status === 1) {
  //           console.log('Order placed successfully:', response);
  //           alert('Order placed successfully!');
  //         } else {
  //           const errorMsg = this.getErrorMessage(response);
  //           console.log('Failed to place the order:', response);

  //           if (errorMsg === 'oauth_problem=token_rejected') {
  //             try {
  //               await this.authManager.fetchBearerToken();
  //               await this.placeOrder(product);
  //             } catch (refreshError) {
  //               console.error('Failed to refresh token:', refreshError);
  //               this.authManager.handleTokenRejected();
  //             }
  //           } else {
  //             alert(`Failed to place the order: ${errorMsg}`);
  //           }


  //         }
  //       },
  //       async (error) => {
  //         this.hideLoadingIndicator();  // Hide loading indicator

  //         console.error('Order placement error:', error);
  //         const errorMsg = this.getErrorMessage(error);
  //         alert(`Error: ${errorMsg}`);

  //         if (error.error && error.error.message.includes('oauth_problem=token_rejected')) {
  //           alert('An error occurred while processing your order. Please login again.');
  //           this.authManager.handleTokenRejected();
  //         }
  //       }
  //     );
  //   } catch (error) {
  //     this.hideLoadingIndicator();  // Hide loading indicator
  //     console.error('Error ensuring bearer token:', error);
  //     alert('An error occurred while processing your order. Please try again.');
  //   }
  // }


  async placeOrder(product: any): Promise<void> {
    if (!product) {
      alert('Please select a product before placing an order.');
      return;
    }

    const hasDenominations = product.selected_denominations && product.selected_denominations.length > 0;

    if (hasDenominations && !product.selectedPrice) {
      alert('Please select a denomination before placing an order.');
      return;
    } else if (!hasDenominations) {
      product.selectedPrice = 100;
    }

    // Confirm order placement only once
    const confirmOrder = confirm('Are you sure you want to place this order?');
    if (!confirmOrder) {
      return;
    }

    // Attempt to place the order
    await this.attemptOrderPlacement(product);
  }

  private async attemptOrderPlacement(product: any): Promise<void> {
    try {
      const bearerToken = await this.authManager.ensureBearerToken();

      const architect_code = JSON.parse(this.profileData).user_code;
      const member_type = JSON.parse(this.profileData).category;
      const voucher_category_id = this.voucher_category_id;

      const formData: FormData = new FormData();
      formData.append('sku', product.sku || '');
      formData.append('price', product.selectedPrice.toString());
      formData.append('qty', this.quantity.toString());
      formData.append('giftMessage', product.product_details.description || '');
      formData.append('architect_code', architect_code);
      formData.append('bearerToken', bearerToken);
      formData.append('voucher_category_id', voucher_category_id);
      formData.append('member_type', member_type);
      formData.append('sync_only', this.quantity <= 4 ? 'true' : 'false');

      this.showLoadingIndicator();  // Show loading indicator

      this.productService.createOrder(formData).subscribe(
        async (response) => {
          this.hideLoadingIndicator();  // Hide loading indicator

          const status = response?.response?.data?.data?.status;

          if (status === 'PROCESSING') {
            alert(`Your Order is in process. Order ID: ${response.response.data.data.orderId} we will let you know when it's processed`);
          } else if (response?.response?.status === 1) {
            console.log('Order placed successfully:', response);
            alert('Order placed successfully!');
          } else {
            const errorMsg = this.getErrorMessage(response);
            console.log('Failed to place the order:', response);

            if (errorMsg === 'oauth_problem=token_rejected') {
              try {
                await this.authManager.fetchBearerToken();
                await this.attemptOrderPlacement(product);
              } catch (refreshError) {
                console.error('Failed to refresh token:', refreshError);
                alert('There is some issue with the server, please hold on for a moment and try again.');
                this.authManager.handleTokenRejected();
              }
            } else {
              alert(`Failed to place the order: ${errorMsg}`);
            }
          }
        },
        async (error) => {
          this.hideLoadingIndicator();  // Hide loading indicator

          console.error('Order placement error:', error);
          const errorMsg = this.getErrorMessage(error);
          alert(`Error: ${errorMsg}`);

          if (error.error && error.error.message.includes('oauth_problem=token_rejected')) {
            alert('An error occurred while processing your order. Please login again.');
            this.authManager.handleTokenRejected();
          }
        }
      );
    } catch (error) {
      this.hideLoadingIndicator();  // Hide loading indicator
      console.error('Error ensuring bearer token:', error);
      alert('An error occurred while processing your order. Please try again.');
    }
  }


  private showLoadingIndicator(): void {
    // Create a loading spinner or message
    const loadingIndicator = document.createElement('div');
    loadingIndicator.id = 'loadingIndicator';
    loadingIndicator.style.position = 'fixed';
    loadingIndicator.style.top = '0';
    loadingIndicator.style.left = '0';
    loadingIndicator.style.right = '0';
    loadingIndicator.style.bottom = '0';
    loadingIndicator.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    loadingIndicator.style.color = 'white';
    loadingIndicator.style.display = 'flex';
    loadingIndicator.style.justifyContent = 'center';
    loadingIndicator.style.alignItems = 'center';
    loadingIndicator.style.zIndex = '9999';
    loadingIndicator.innerText = "Processing... Please don't refresh the page until the order is processed.";  // Loading text
    document.body.appendChild(loadingIndicator);
  }

  private hideLoadingIndicator(): void {
    const loadingIndicator = document.getElementById('loadingIndicator');
    if (loadingIndicator) {
      loadingIndicator.remove();  // Remove loading indicator from DOM
    }
  }




  private getErrorMessage(response: any): string {
    if (response?.response?.data?.data?.message) {
      return response.response.data.data.message;
    }
    if (response?.response?.msg) {
      return response.response.msg;
    }
    if (response?.error?.data?.message) {
      return response.error.data.message;
    }
    return 'An unexpected error occurred. Please try again later.';
  }



}

