<main>


    <!-- Banner -->
    <div class="breadcrumb__area breadcrumb__overlay breadcrumb__height p-relative fix"
        data-background="assets/img/breadcurmb/breadcurmb.jpg"
        style="background-image: url(assets/images/breadcurmb/breadcurmb.jpg);">
        <div class="container">
            <div class="row">
                <div class="col-xxl-12">
                    <div class="breadcrumb__content z-index text-center">
                        <h3 class="breadcrumb__title">Gift Vouchers</h3>
                        <div class="breadcrumb__list">
                            <span><a class="no-underline" routerLink="/index">Home</a></span>
                            <span class="dvdr"><i class="fa fa-angle-right"></i></span>
                            <span>Gift Vouchers</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="feature-one-page" class="tp-feature-area pt-130 pb-110 p-relative z-index grey-bg-2">
        <div class="tp-feature-shape-1 d-none d-xxl-block">
            <img src="images/ab-shape-2.png" alt="">
        </div>
        <div class="tp-feature-shape-2">
            <img src="images/ab-bg.png" alt="">
        </div>
        <div class="container">

            <div class="row row-cols-xl-5 row-cols-lg-3 justify-content-center">
                <div *ngFor="let product of products; let i = index" class="col col-sm-6 wow tpfadeUp"
                    data-wow-duration=".9s" data-wow-delay=".3s"
                    style="visibility: visible; animation-duration: 0.9s; animation-delay: 0.3s; animation-name: tpfadeUp;">
                    <div (click)="onCardClick(product); setIndex(i)" [class.active]="selectedIndex === i"
                        class="tp-feature-item mb-30 text-center card-wrapper">
                        <div class="tp-feature-icon">
                            <img [src]="product.images" alt="{{ product.name }}">
                        </div>
                        <div class="tp-feature-content">
                            <h4 class="tp-feature-title-sm">{{ product.name }}</h4>
                        </div>
                    </div>

                    <!-- <a (click)="onCardClick(product)" [routerLink]="[]" class="tp-feature-item mb-30 text-center card-wrapper" href="#product-details">
                    <div class="tp-feature-icon">
                      <img [src]="product.images" alt="{{ product.name }}">
                    </div>
                    <div class="tp-feature-content">
                      <h4 class="tp-feature-title-sm">{{ product.name }}</h4>
                    </div>
                  </a> -->

                </div>
            </div>

            <!-- <div class="row justify-content-center">
                <div *ngFor="let product of products" class="col-xs-12 col-sm-6 col-md-3">
                    <div class="service-item active" (click)="onCardClick(product)">
                        <div class="service-icon">
                            <img [src]="product.images" alt="{{ product.name }}">
                        </div>
                        <div class="service-content">
                            <h6>{{ product.name }}</h6>
                            <span class="d-flex align-items-center gap-2">
                                <span class="inter">{{ product.price }}</span>
                                <span class="">{{ product.discount }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div> -->


            <!-- Content Section -->
            <div *ngIf="selectedProduct" class="col-md-12 mt-4">
                <div class="service-details p-0" id="giftCardDetails">
                    <div class="service-content">
                        <div class="row">
                            <div class="col-md-6">
                                <img src="assets/images/blog49-1.jpg" alt="">
                                <!-- You can use the product details image if available -->
                                <!-- <img [src]="selectedProduct.product_details.images.thumbnail" alt="{{ selectedProduct.product_details.name }}"> -->
                            </div>
                            <div class="col-md-6">
                                <div class="p-4">
                                    <h6>{{ selectedProduct.product_details.name }}</h6>
                                    <p>{{ selectedProduct.product_details.description }}</p>
                                    <!-- <p>{{selectedProduct.selected_denomination}}</p> -->
                                    <!-- <ul class="text-start horizontal-radio-buttons">
                                        <li class="form-group"
                                            *ngFor="let denomination of selectedProduct.selected_denominations">
                                            <input type="radio" [id]="denomination" [name]="denomination"
                                                [value]="denomination" (change)="onCheckboxChange($event)">
                                            <label [for]="denomination"> {{ denomination }}</label>
                                        </li>
                                    </ul> -->

                                    <!-- <ul class="text-start horizontal-radio-buttons">
                                        <li class="form-group" *ngFor="let denomination of selectedProduct.selected_denominations; let i = index">
                                          <input type="radio" 
                                                 [id]="'denomination-' + i + '-' + selectedProduct.sku"
                                                 [name]="'denomination-' + selectedProduct.sku"
                                                 [value]="denomination" 
                                                 (change)="onCheckboxChange($event, selectedProduct)">
                                          <label [for]="'denomination-' + i + '-' + selectedProduct.sku"> {{ denomination }} </label>
                                        </li>
                                      </ul> -->


                                    <ul class="text-start horizontal-radio-buttons">
                                        <li class="form-group"
                                            *ngFor="let denomination of selectedProduct.selected_denominations; let i = index">
                                            <input type="radio" [id]="'denomination-' + i + '-' + selectedProduct.sku"
                                                [name]="'denomination-' + selectedProduct.sku" [value]="denomination"
                                                [checked]="selectedProduct.selectedPrice === denomination"
                                                (change)="onCheckboxChange($event, selectedProduct)">
                                            <label [for]="'denomination-' + i + '-' + selectedProduct.sku"> {{
                                                denomination }} </label>
                                        </li>
                                    </ul>

                                    <div class="form-group mt-2">
                                        <label for="quantity">Quantity:</label>
                                        <input type="number" id="quantity" [(ngModel)]="quantity"
                                            min="1" max="5" class="form-control small" required>
                                    </div>

                                    <form (submit)="placeOrder(selectedProduct)">
                                        <button type="submit" class="btn btn-primary mt-2 custom-order-button">Place
                                            Order</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</main>