import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../category.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';


@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.css']
})
export class WebinarsComponent {

  showTable = false;
  showVoucherTable = false;

  tableData: any[] = [];
  voucherData: any[] = [];
  profileData: any;

  selectedCategoryName: string = '';  

  categories: any[] = [];
  private token: any = '609615431cd8d539b733876c7e0c511dSAGLcHZ6nxEBnE4XlJ1nmcPTZaOXOGIXW';
  listiem_id: any;
  selectedIndex: any;

  constructor(
    private categoryService: CategoryService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.getCat();
    this.profileData = localStorage.getItem('profileData');
  }

  //---------------------Category--------------------------------
  getCat() {
    this.categoryService.getCategories(this.token).subscribe(
      (response: any) => {
        // console.log(response);
        this.categories = Object.values(response.response.data);
        // console.log(this.categories);
      },
      (error) => {
        console.error('Error fetching categories', error);
      }
    );
  }
  //-------------------------------------------------------------

  // Handle Category Click
  onCategoryClick(categoryId: number): void {
    const selectedCategory = this.categories.find((category: { id: number; }) => category.id === categoryId);
    //console.log('Selected Category:', selectedCategory);
    this.selectedIndex = selectedCategory.id;
    console.log(this.selectedIndex);

    if (!selectedCategory) {
      // this.selectedCategoryName = selectedCategory.category_name;
      // this.showTable = true;
        // console.error('Category not found');
        alert('Category not found');
        return; 
    }

    const params = {
        category_id: selectedCategory.id,
        user_code: JSON.parse(this.profileData).user_code,
        token: this.token
    };

    

    if (selectedCategory.table_status === "active") {
        // console.log('Fetching edited data...');
        this.fetchEditedData(params.category_id, params.user_code);
        // alert('Fetching voucher items...');
    } else {
        // console.log('Fetching voucher items...');
        this.fetchVoucherItems(params.user_code);
        // alert('Fetching voucher items...');
    }
}


  // Fetch Edited Data
  fetchEditedData(categoryId: number, user_code: string): void {
    this.categoryService.getEditedData(categoryId, user_code, this.token).subscribe(
      (response: any) => {
        this.tableData = response.response.data;
        this.showTable = true;
        this.showVoucherTable = false;
      },
      (error) => {
        alert('Error fetching the data');
        // console.error('Error fetching edited data', error);
      }
    );
  }
  
  // Voucher Data
  fetchVoucherItems(user_code?: any): void {
    if (this.profileData) {
      const user_code = JSON.parse(this.profileData).user_code;
      this.categoryService.getVoucherData(user_code, this.token).subscribe(
        (response: any) => {
          this.voucherData = response.response.data.map((product: { product_details: string; response_data:any; }) => {
            return {
              ...product,
              product_details: JSON.parse(product.product_details),
              response_data: JSON.parse(product.response_data)
            };
          });;
          console.log(this.voucherData);
          this.showTable = false;
          this.showVoucherTable = true;
        },
        (error) => {
          // console.error('Error fetching voucher items', error);
          alert('Error fetching voucher items');
        }
      );
      } else {
      // console.error('Profile data is missing');
      alert('Profile data is missing');
    }
  }

  // Remove Items
  // removeItem() {
  //   const user_code = JSON.parse(this.profileData).user_code;
  //   this.categoryService.removeItem(this.listitem_id, user_code, this.token).subscribe(
  //     (response: any) => {
  //       this.tableData = response.response.data;
  //       // Optionally, you can remove the item from the local tableData if not already handled
  //       this.tableData = this.tableData.filter(data => data.listitem_id !== item.listitem_id);
  //     },
  //     (error) => {
  //       console.error('Error removing item', error);
  //     }
  //   );
  // }

  deleteItem(listitem_id: string, token: string): void {
    const user_code = JSON.parse(this.profileData).user_code;
    this.categoryService.removeItem(user_code, listitem_id, this.token).pipe(
      catchError(error => {
        // console.error('Error deleting item', error);
        alert('Error deleting item');
        return of(null); // Return an observable to complete the pipe
      })
    ).subscribe(response => {
      if (response) {
        // Optionally handle response or update UI
        // console.log('Item deleted successfully');
        alert('Item deleted successfully');
        this.tableData = this.tableData.filter(item => item.listitem_id !== listitem_id);
      }
    });
  }




}
